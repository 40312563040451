<!-- 分步骤add -->
<template>
  <ykc-form class="el-form-wrap">
    <el-steps :active="active" align-center finish-status="success" v-if="!isEdit">
      <el-step title="商户属性" />
      <el-step title="经营属性" />
    </el-steps>
    <ykc-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm0"
      class="el-form-wrap"
      v-if="!isEdit"
      v-show="active === 0 && !isEdit">
      <ykc-form-item label="商户名称" prop="name">
        <ykc-input
          placeholder="中文、英文、数字（1~30字数），不可重复"
          maxlength="50"
          v-model="ruleForm.name"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="公司名称" prop="companyName">
        <ykc-input placeholder="可重复" maxlength="50" v-model="ruleForm.companyName"></ykc-input>
      </ykc-form-item>
      <div>
        <ykc-form-item label="归属地区" prop="city">
          <ykc-cascader
            v-model="ruleForm.city"
            :options="cityData"
            @change="selectChange"></ykc-cascader>
        </ykc-form-item>
      </div>
      <ykc-form-item
        class="img-error"
        label="统一社会信用代码"
        prop="socialCreditCode"
        bottomTip="温馨提示：请认真核对营业执照上的18位编码">
        <ykc-input
          placeholder="请输入统一社会信用代码"
          maxlength="18"
          minlenght="18"
          v-model="ruleForm.socialCreditCode"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="商户管理员" prop="manageName">
        <ykc-input
          placeholder="请输入商户管理员姓名"
          maxlength="50"
          v-model="ruleForm.manageName"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="联系方式" prop="contactInfo">
        <ykc-input
          placeholder="请输入商户管理员联系方式"
          maxlength="11"
          v-model="ruleForm.contactInfo"></ykc-input>
      </ykc-form-item>
    </ykc-form>
    <ykc-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm1"
      class="el-form-wrap"
      v-if="active === 1 || isEdit">
      <ykc-form-item label="开票种类">
        <ykc-radio
          :data="this.billForm"
          @change="handleBill"
          v-model="ruleForm.billType"></ykc-radio>
      </ykc-form-item>

      <ykc-form-item prop="image" label="营业执照" class="file-error" bottomTip=" ">
        <ykc-upload
          ref="licence"
          :size="1"
          @handleRemove="removeLicence"
          @handleChange="uploadBusinessLicence"></ykc-upload>
      </ykc-form-item>

      <ykc-form-item
        prop="image"
        label="站点LOGO"
        class="file-error img-error"
        bottomTip="温馨提示：建议上传图片尺寸为20*20">
        <ykc-upload
          ref="stationLogo"
          @handleRemove="removeStationLogo"
          @handleChange="uploadStationLogo"></ykc-upload>
      </ykc-form-item>

      <ykc-form-item
        prop="image"
        label="平台LOGO"
        class="file-error img-error"
        bottomTip="温馨提示：建议上传图片尺寸为80*73">
        <ykc-upload
          ref="platformLogo"
          @handleRemove="removePlatformLogo"
          @handleChange="uploadPlatformLogo"></ykc-upload>
      </ykc-form-item>
    </ykc-form>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { filterByIdList } from '@/utils/index';
  import { operatorData, treeUtils, uploadUtils } from '@/service/apis';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        detail: {},
        cityData: [],
        dictionary: {},
        organizations: [],
        billForm: [
          { id: 1, name: '普票' },
          { id: 2, name: '专票' },
        ],
        pictureLicence: [
          // y营业执照
          {
            name: '上传图片',
            url: '',
          },
        ],
        pictureStationLogo: [
          // 电站logo
          {
            name: '上传图片',
            url: '',
          },
        ],
        picturePlatformLogo: [
          // 平台logo
          {
            name: '上传图片',
            url: '',
          },
        ],
        ruleForm: {
          operatorType: '1', // 平台商户
          operatorId: '', // 编辑需要传
          name: '',
          companyName: '',
          districtId: [],
          socialCreditCode: '',
          manageName: '',
          contactInfo: '',
          billType: '',
          businessLicence: '',
          stationLogo: '',
          platformLogo: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入商户名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('商户名称'),
            },
          ],
          companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('公司名称'),
            },
          ],
          districtId: [{ required: true, message: '请选择归属地区', trigger: 'blur' }],
          socialCreditCode: [
            { required: true, message: '请输入18位社会统一信用代码', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.creditCode.regexp,
              message: regexpObj.regexp.input.creditCode.errorTips.error('社会统一信用代码'),
            },
          ],
          manageName: [
            { required: true, message: '请输入商户管理员名称', trigger: 'change' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('商户管理员名称'),
            },
          ],
          contactInfo: [
            { required: true, message: '请输入商户管理员联系方式', trigger: 'change' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.contactType.regexp,
              message: regexpObj.regexp.input.contactType.errorTips.error('商户管理员联系方式'),
            },
          ],
          billType: [{ required: false, message: '请选择开票种类', trigger: 'change' }],
          businessLicence: [{ required: false, message: '请上传营业执照', trigger: 'change' }],
          stationLogo: [{ required: false, message: '请上传站点logo', trigger: 'change' }],
          platformLogo: [{ required: false, message: '请上传站点logo', trigger: 'change' }],
        },
      };
    },
    created() {
      // 编辑回显
      if (this.id) {
        operatorData
          .operatorDetail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
            this.ruleForm.city = [res.provinceId, res.districtId];
            console.log(res.businessLicence);
            this.$refs.licence.fileList = [{ url: this.ruleForm.businessLicence }];
            this.$refs.stationLogo.fileList = [{ url: this.ruleForm.stationLogo }];
            this.$refs.platformLogo.fileList = [{ url: this.ruleForm.platformLogo }];

            this.getTreeData(true, res.configIds);
          })
          .catch(() => {});
      }
      this.getCityTree();
    },
    computed: {
      /**
       * 车队列表默认展示符
       * */
      organizationPlaceHolder() {
        if (this.organizations.length) {
          return '请输入归属车队';
        }
        return '暂无数据';
      },
      formData() {
        const formData = {
          operatorType: '1',
          operatorId: this.id, // 编辑需要传
          name: this.ruleForm.name,
          companyName: this.ruleForm.companyName || '',
          districtId: this.ruleForm.districtId || '',
          provinceId: this.ruleForm.provinceId || '',
          socialCreditCode: this.ruleForm.socialCreditCode,
          manageName: this.ruleForm.manageName,
          contactInfo: this.ruleForm.contactInfo,
          billType: this.ruleForm.billType,
          businessLicence: this.ruleForm.businessLicence,
          stationLogo: this.ruleForm.stationLogo,
          platformLogo: this.ruleForm.platformLogo || '',
        };

        return formData;
      },
    },
    methods: {
      filterByIdList,
      uploadBusinessLicence(file, fileList, isCheck) {
        this.uploadImg(file, fileList, isCheck, 'businessLicence');
      },
      uploadStationLogo(file, fileList, isCheck) {
        this.uploadImg(file, fileList, isCheck, 'stationLogo');
      },
      uploadPlatformLogo(file, fileList, isCheck) {
        this.uploadImg(file, fileList, isCheck, 'platformLogo');
      },
      removeLicence(file, fileList, text) {
        console.log(JSON.stringify(file), JSON.stringify(fileList), JSON.stringify(text));
        this.ruleForm.businessLicence = '';
      },
      removeStationLogo(file, fileList, text) {
        console.log(JSON.stringify(file), JSON.stringify(fileList), JSON.stringify(text));
        this.ruleForm.stationLogo = '';
      },
      removePlatformLogo(file, fileList, text) {
        console.log(JSON.stringify(file), JSON.stringify(fileList), JSON.stringify(text));
        this.ruleForm.platformLogo = '';
      },
      uploadImg(file, fileList, isCheck, type) {
        console.log(file, fileList, isCheck);
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            console.log(JSON.stringify(res));
            this.ruleForm[type] = res;
            console.log(this.ruleForm);
          });
        }
      },
      getCityTree() {
        treeUtils.getDistrict({ level: '3' }).then(res => {
          this.cityData = res.districts;
        });
      },
      /**
       * 按城市
       */
      selectChange(value) {
        console.log(value[1]);
        // eslint-disable-next-line no-unused-expressions, prefer-destructuring
        this.ruleForm.provinceId = value[0];
        // eslint-disable-next-line prefer-destructuring
        this.ruleForm.districtId = value[1];
      },
      // 修改开票种类
      handleBill(e) {
        console.log(e);
      },
      /**
       * 方案名称唯一性校验
       */
      onlyCheckRuleName(rule, value, callback) {
        if (value) {
          operatorData
            .checkName({ ruleName: value })
            .then(res => {
              if (res?.data?.resultCode !== '0') {
                this.nameValidError = res.data.resultDesc;
                callback(new Error(this.nameValidError));
              } else {
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              operatorData
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              operatorData
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log('', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
              setTimeout(() => {
                if (this.ruleForm.businessLicence !== '') {
                  this.$refs.licence.fileList = [{ url: this.ruleForm.businessLicence }];
                }
                if (this.ruleForm.stationLogo !== '') {
                  this.$refs.stationLogo.fileList = [{ url: this.ruleForm.stationLogo }];
                }
                if (this.ruleForm.platformLogo !== '') {
                  this.$refs.platformLogo.fileList = [{ url: this.ruleForm.platformLogo }];
                }
              }, 100);
            } else {
              reject();
            }
          });
        });
      },
      /** 校验所有表格是否通过 */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .upload-img-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
